// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "popper.js";
import "bootstrap";
import toastr from "toastr";
import "select2";
import "@fortawesome/fontawesome-free/js/all"

import "./application/users/profiles/legal/beneficiaries_step.js";
import "./application/users/profiles/legal/profile_step.js";
import "./application/users/profiles/legal/fatca_step.js";
import "./application/users/profiles/legal/objectives_step.js";
import "./application/users/profiles/legal/financial_situation_step.js";
import "./application/users/profiles/legal/assets_step.js";
import "./application/users/profiles/legal/knowledge_step.js";

import "./application/users/profiles/natural/client_id_step.js";
import "./application/users/profiles/natural/additional_info_step.js";
import "./application/users/profiles/natural/professional_situation_step.js";
import "./application/users/profiles/natural/objectives_step.js";

import "./application/users/subscriptions/details_step.js";

toastr.options.toastClass = 'toastr';

function chooseProfileForInvestment() {
  if ($('select#profile_id')[0] && $('a#select-share-link')[0]) {
    const profile = $('select#profile_id').val();

    $('a#select-share-link').attr('href', $('a#select-share-link').attr('href').replace(/&profile_id=\d*&?/, '') + `&profile_id=${profile}`);
  }
}

function updateConfirmationText() {
  const val = $('form input#subscription_amount').val();

  if (val) {
    $('span#confirmation-text-model-amount').html($('form input#subscription_amount').val());
    $('#confirmation-text-model').change();
  }
}

$(document).ready(() => {
  if ($('.flash.info')[0]) {
    toastr["info"]($('.flash.info').html());
  }
  if ($('.flash.success')[0]) {
    toastr["success"]($('.flash.success').html());
  }
  if ($('.flash.error')[0]) {
    toastr["error"]($('.flash.error').html());
  }
  if ($('.flash.alert')[0]) {
    toastr["error"]($('.flash.alert').html());
  }

  $('select').select2();

  $('select#profile_id').on('change', chooseProfileForInvestment);
  chooseProfileForInvestment();

  updateConfirmationText();
  $('form input#subscription_amount').on('input', updateConfirmationText);

  $('body').on('change', '.custom-file-input', function() {
    if ($(this)[0].files[0] !== undefined) {
      $(this)
        .siblings(".custom-file-label")
        .html($(this)[0].files[0].name);
    }
  });
});
